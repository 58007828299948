import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { noNumbersValidator, textInputValidator } from '../../validators/text-validator';

@Component({
  selector: 'app-company-address-field-dialog',
  templateUrl: './company-address-field-dialog.component.html',
  styleUrls: ['./company-address-field-dialog.component.scss'],
})
export class CompanyAddressFieldDialogComponent implements OnInit {
  formValueChangeSubscription: Subscription;
  companyFormGroup: UntypedFormGroup;
  streetValue: string;
  houseNumberValue: number;
  houseNumberExtensionValue: string;
  saveEnabled = false;
  showSpinner: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CompanyAddressFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.companyFormGroup = this.fb.group({
      streetFormControl: [this.streetValue, [Validators.required, textInputValidator(), noNumbersValidator(), Validators.maxLength(100)]],
      houseNumberFormControl: [this.houseNumberValue, [Validators.pattern('^[0-9]*$')]],
      houseNumberExtensionFormControl: [this.houseNumberExtensionValue, [textInputValidator(), Validators.maxLength(100)]],
    }, { validators: this.atLeastOneRequiredValidator('houseNumberFormControl', 'houseNumberExtensionFormControl') });
  }

  atLeastOneRequiredValidator(controlName1: string, controlName2: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control1 = formGroup.get(controlName1);
      const control2 = formGroup.get(controlName2);

      if (control1 && control2 && (!control1.value && !control2.value)) {
        return { atLeastOneRequired: true };
      }
      return null;
    };
  }

  noNumberInput(event, field) {
    try {
      if ((event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)) {
        return false;
      } else {
        this.changeDetection(field);
        return true;
      }
    } catch (error) {
      // handle error
      console.log('Company address street error');
      console.log(error);
    }
  }

  numberInput(event, field) {
    try {
      if (event.shiftKey) {
        return false;
      } else if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode == 8 ||
        event.keyCode == 9 ||
        event.keyCode == 37 ||
        event.keyCode == 39
      ) {
        this.changeDetection(field);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('Input error');
      console.log(error);
    }
  }

  streetValuechange(event, field) {
    // remove any characters that repeat more than 2 times consecutively
    this.companyFormGroup.get(field).patchValue(event.target.value.replace(/(.)\1{2,}/g, ''));
  }

  numbericValuechange(event, field) {
    const regExp = new RegExp('^[0-9]*$');
    if (!event.target.value.match(regExp)) {
      this.companyFormGroup.get(field).patchValue(null);
    }
  }

  changeDetection(field) {
    this.saveEnabled = true;
    this.companyFormGroup.get(field).markAsTouched();
    this.companyFormGroup.get(field).updateValueAndValidity();
  }

  closeDialog() {
    this.dialogRef.close({
      streetValue: this.companyFormGroup.get('streetFormControl').value,
      houseNumberValue: this.companyFormGroup.get('houseNumberFormControl').value,
      houseNumberExtensionValue: this.companyFormGroup.get('houseNumberExtensionFormControl').value,
    });
  }

  ngOnDestroy() {
    if (this.formValueChangeSubscription) {
      this.formValueChangeSubscription.unsubscribe();
    }
  }
}
