<div class="content-container">
  <div [formGroup]="companyFormGroup">
    <div class="message-label" [innerHTML]="'MyPlukonCompany.ChangeAddress' | translate"></div>
    <div class="field-div">
      <label class="input-label">{{'MyPlukonCompany.Street' | translate}}</label>
      <div class="street-input-field input-field" fxLayout="column">
        <mat-form-field>
          <div [ngClass]="{'input-enabled': companyFormGroup.get('streetFormControl').enabled}">
            <input
              matInput
              formControlName="streetFormControl"
              (change)="streetValuechange($event, 'streetFormControl')"
              (keydown)="noNumberInput($event, 'streetFormControl')"
              maxlength="100">
          </div>
          <mat-error *ngIf="companyFormGroup.get('streetFormControl').hasError('required')">
            <mat-error>{{'MyPlukonCompany.StreetRequired' | translate}}</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="field-div">
      <label class="input-label">{{'MyPlukonCompany.HouseNumber' | translate}}</label>
      <div class="house-number-input-field input-field" fxLayout="column">
        <mat-form-field>
          <div [ngClass]="{'input-enabled': companyFormGroup.get('houseNumberFormControl').enabled}">
            <input
              matInput
              formControlName="houseNumberFormControl"
              (change)="numbericValuechange($event, 'houseNumberFormControl')"
              (keydown)="numberInput($event, 'houseNumberFormControl')">
          </div>
        </mat-form-field>
      </div>
    </div>
    <div class="field-div">
      <label class="input-label">{{'MyPlukonCompany.HouseNumberExtension' | translate}}</label>
      <div class="house-number-extension-input-field input-field" fxLayout="column">
        <mat-form-field>
          <div [ngClass]="{'input-enabled': companyFormGroup.get('houseNumberExtensionFormControl').enabled}">
            <input
              matInput
              formControlName="houseNumberExtensionFormControl"
              (keydown)="changeDetection('houseNumberExtensionFormControl')"
              maxlength="100">
          </div>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="companyFormGroup.hasError('atLeastOneRequired')" class="field-div">
      <mat-error>{{'MyPlukonCompany.houseNumberRequired' | translate}}</mat-error>
    </div>
  </div>
  <mat-dialog-actions style="justify-content: center;">
    <button class="button-enabled" mat-button mat-dialog-close (click)="dialogRef.close()">{{'Common.Cancel' | translate}}</button>
    <button [ngClass]="{'button-disabled': !companyFormGroup.valid || !saveEnabled, 'button-enabled': companyFormGroup.valid && saveEnabled}" mat-button (click)="closeDialog()" [disabled]="!companyFormGroup.valid || !saveEnabled">{{'Common.Save' | translate}}</button>
  </mat-dialog-actions>
  <i class="material-icons" (click)="dialogRef.close()">clear</i>
  <app-loading-spinner [show]=showSpinner></app-loading-spinner>
</div>
