import { Injectable, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { ContentDialogComponent } from '../components/content-dialog/content-dialog.component';
import { SlaughterdateDialogComponent } from '../components/slaughterdate-dialog/slaughterdate-dialog.component';
import { StartdateDialogComponent } from '../components/startdate-dialog/startdate-dialog.component';
import { CancelSubmitDialogComponent } from '../components/cancel-submit-dialog/cancel-submit-dialog.component';
import { ContactDialogComponent } from '../components/contact-dialog/contact-dialog.component';
import { AccessSettingsDialogComponent } from '../components/access-settings-dialog/access-settings-dialog.component';
import { CumulativeConfirmationDialogComponent } from '../components/cumulative-confirmation-dialog/cumulative-confirmation-dialog.component';
import { ChickenDeliverDialogComponent } from '../components/chicken-deliver-dialog/chicken-deliver-dialog.component';
import { EmailDocumentDialogComponent } from '../components/email-document-dialog/email-document-dialog.component';
import * as _ from 'lodash';
import { EmailSharingDialogComponent } from '../components/email-sharing-dialog/email-sharing-dialog.component';
import { ContentReverseButtonDialogComponent } from '../components/content-reverse-button-dialog/content-reverse-button-dialog.component';
import { CompanyFieldDialogComponent } from '../components/company-field-dialog/company-field-dialog.component';
import { VaccinationsDialogComponent } from '../../modules/my-flock/vki-data/vaccinations/vaccinations-dialog/vaccinations-dialog.component';
import { MedicationDialogComponent } from 'src/app/modules/my-flock/vki-data/medication/medication-dialog/medication-dialog.component';
import { CoccidiostatsDialogComponent } from '../../modules/my-flock/vki-data/coccidiostats/coccidiostats-dialog/coccidiostats-dialog.component';
import { SalmonellaDialogComponent } from '../../modules/my-flock/vki-data/salmonella/salmonella-dialog/salmonella-dialog.component';
import { StableTechnicalDialogComponent } from '../../modules/my-flock/stable-technical/stable-technical-dialog/stable-technical-dialog.component';
import { StableCardDialogComponent } from '../../modules/my-flock/stable-card/stable-card-dialog/stable-card-dialog.component';
import { NotificationsReminderModalComponent } from '../../components/notifications-reminder-modal/notifications-reminder-modal.component';
import { SingleActionDialogComponent } from '../components/single-action-dialog/single-action-dialog.component';
import { CompanyAddressFieldDialogComponent } from '../components/company-address-field-dialog/company-address-field-dialog.component';
import { LossesEditAnimalDialogComponent } from '../components/losses-edit-animal-dialog/losses-edit-animal-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  myTaskDialogRef: MatDialogRef<ContentDialogComponent>;

  constructor(private dialog: MatDialog) {}

  public confirm(content: string, isConFirm: boolean, isTranslate?: boolean): Observable<boolean> {
    let dialogRef: MatDialogRef<DialogComponent>;
    dialogRef = this.dialog.open(DialogComponent, { autoFocus: false });
    dialogRef.componentInstance.content = content;
    dialogRef.componentInstance.isConfirm = isConFirm; //isConfirmDialog
    dialogRef.componentInstance.isTranslate = isTranslate;
    return dialogRef.afterClosed();
  }

  public context(
    content: string,
    isHtml?: boolean,
    primaryButtonText?: string,
    secondaryButtonText?: string,
    showGif?: boolean,
    isTranslateString?: boolean,
    isHelp?: boolean,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ContentDialogComponent>;
    dialogRef = this.dialog.open(ContentDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.content = content;
    dialogRef.componentInstance.showGif = showGif;
    dialogRef.componentInstance.isHtml = isHtml;
    dialogRef.componentInstance.primaryButtonText = primaryButtonText;
    dialogRef.componentInstance.secondaryButtonText = secondaryButtonText;
    dialogRef.componentInstance.isTranslateString = isTranslateString;
    dialogRef.componentInstance.isHelp = isHelp;
    return dialogRef.afterClosed();
  }

  public contextReverseButton(
    content: string,
    isHtml?: boolean,
    primaryButtonText?: string,
    secondaryButtonText?: string,
    showGif?: boolean,
    hideCloseIcon?: boolean,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ContentReverseButtonDialogComponent>;
    dialogRef = this.dialog.open(ContentReverseButtonDialogComponent, { autoFocus: false });
    dialogRef.disableClose = hideCloseIcon;
    dialogRef.componentInstance.content = content;
    dialogRef.componentInstance.showGif = showGif;
    dialogRef.componentInstance.isHtml = isHtml;
    dialogRef.componentInstance.primaryButtonText = primaryButtonText;
    dialogRef.componentInstance.secondaryButtonText = secondaryButtonText;
    dialogRef.componentInstance.hideCloseIcon = hideCloseIcon;
    return dialogRef.afterClosed();
  }

  public singleActionRef(translationKey: string, isHtml?: boolean, primaryButtonText?: string) {
    let dialogRef: MatDialogRef<SingleActionDialogComponent>;
    dialogRef = this.dialog.open(SingleActionDialogComponent, { autoFocus: false });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.translationKey = translationKey;
    dialogRef.componentInstance.isHtml = isHtml;
    dialogRef.componentInstance.primaryButtonText = primaryButtonText;
    return dialogRef;
  }

  public singleAction(translationKey: string, isHtml?: boolean, primaryButtonText?: string): Observable<boolean> {
    let dialogRef: MatDialogRef<SingleActionDialogComponent>;
    dialogRef = this.dialog.open(SingleActionDialogComponent, { autoFocus: false });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.translationKey = translationKey;
    dialogRef.componentInstance.isHtml = isHtml;
    dialogRef.componentInstance.primaryButtonText = primaryButtonText;
    return dialogRef.afterClosed();
  }

  public vkiSlaughterDatePopup(vkiSlaughterDate: string, flockStartDate: string): Observable<boolean> {
    let dialogRef: MatDialogRef<SlaughterdateDialogComponent>;
    dialogRef = this.dialog.open(SlaughterdateDialogComponent);
    dialogRef.componentInstance.vkiSlaughterDate = vkiSlaughterDate;
    dialogRef.componentInstance.flockStartDate = flockStartDate;
    return dialogRef.afterClosed();
  }

  public vkiStartDatePopup(flockStartDate: string): Observable<boolean> {
    let dialogRef: MatDialogRef<StartdateDialogComponent>;
    dialogRef = this.dialog.open(StartdateDialogComponent);
    dialogRef.componentInstance.flockStartDate = flockStartDate;
    return dialogRef.afterClosed();
  }

  public chickenDeliverPopup(chickenDelivered: number): Observable<boolean> {
    let dialogRef: MatDialogRef<ChickenDeliverDialogComponent>;
    dialogRef = this.dialog.open(ChickenDeliverDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.chickenDelivered = chickenDelivered;
    return dialogRef.afterClosed();
  }

  public submitDialog(): Observable<boolean> {
    let dialogRef: MatDialogRef<CancelSubmitDialogComponent>;
    dialogRef = this.dialog.open(CancelSubmitDialogComponent);
    return dialogRef.afterClosed();
  }

  public cumulativeConfirmationPopup(): Observable<boolean> {
    let dialogRef: MatDialogRef<CumulativeConfirmationDialogComponent>;
    dialogRef = this.dialog.open(CumulativeConfirmationDialogComponent, { autoFocus: false });
    return dialogRef.afterClosed();
  }

  public contactDialog(
    userId,
    mainEnvironment,
    environmentName,
    contactName,
    contactEmail,
    contactAddress,
    contactTelephone,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ContactDialogComponent>;
    dialogRef = this.dialog.open(ContactDialogComponent, {
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '90vh',
      height: 'fit-content',
    });
    dialogRef.componentInstance.userId = userId;
    dialogRef.componentInstance.mainEnvironment = mainEnvironment;
    dialogRef.componentInstance.environmentName = environmentName;
    dialogRef.componentInstance.contactName = contactName;
    dialogRef.componentInstance.contactName = contactName;
    dialogRef.componentInstance.contactEmail = contactEmail;
    dialogRef.componentInstance.contactAddress = contactAddress;
    dialogRef.componentInstance.contactTelephone = contactTelephone;
    return dialogRef.afterClosed();
  }

  public accessSettingsPopup(data, ownerCompany, isNew): Observable<boolean> {
    let dialogRef: MatDialogRef<AccessSettingsDialogComponent>;
    dialogRef = this.dialog.open(AccessSettingsDialogComponent);
    dialogRef.componentInstance.data = data;
    dialogRef.componentInstance.ownerCompany = ownerCompany;
    dialogRef.componentInstance.isNew = isNew;
    return dialogRef.afterClosed();
  }

  public emailDocumentPopup(companyId, data, canSend): Observable<boolean> {
    let dialogRef: MatDialogRef<EmailDocumentDialogComponent>;
    dialogRef = this.dialog.open(EmailDocumentDialogComponent, {
      autoFocus: false,
    });
    dialogRef.componentInstance.companyId = companyId;
    dialogRef.componentInstance.data = data;
    dialogRef.componentInstance.canSend = canSend;
    return dialogRef.afterClosed();
  }

  public emailDocSharingPopup(email, companyId, isNew, data, documentList, existingEmailList): Observable<boolean> {
    let dialogRef: MatDialogRef<EmailSharingDialogComponent>;
    dialogRef = this.dialog.open(EmailSharingDialogComponent, {
      autoFocus: false,
    });
    dialogRef.componentInstance.email = email;
    dialogRef.componentInstance.companyId = companyId;
    dialogRef.componentInstance.isNew = isNew;
    dialogRef.componentInstance.data = data;
    dialogRef.componentInstance.documentList = documentList;
    dialogRef.componentInstance.existingEmailList = existingEmailList;
    return dialogRef.afterClosed();
  }

  public myTaskContext(
    content: string,
    isHtml?: boolean,
    primaryButtonText?: string,
    secondaryButtonText?: string,
    showGif?: boolean,
    isTranslateString?: boolean,
  ): Observable<boolean> {
    this.myTaskDialogRef = this.dialog.open(ContentDialogComponent, { autoFocus: false, disableClose: true });
    this.myTaskDialogRef.disableClose = true;
    this.myTaskDialogRef.componentInstance.isMyTask = true;
    this.myTaskDialogRef.componentInstance.content = content;
    this.myTaskDialogRef.componentInstance.showGif = showGif;
    this.myTaskDialogRef.componentInstance.isHtml = isHtml;
    this.myTaskDialogRef.componentInstance.primaryButtonText = primaryButtonText;
    this.myTaskDialogRef.componentInstance.secondaryButtonText = secondaryButtonText;
    this.myTaskDialogRef.componentInstance.isTranslateString = isTranslateString;
    return this.myTaskDialogRef.afterClosed();
  }

  public context2FA(
    content: string,
    isHtml?: boolean,
    primaryButtonText?: string,
    secondaryButtonText?: string,
    showGif?: boolean,
    isTranslateString?: boolean,
    manualUrl?: string,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ContentDialogComponent>;
    dialogRef = this.dialog.open(ContentDialogComponent, { autoFocus: false });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.is2FA = true;
    dialogRef.componentInstance.content = content;
    dialogRef.componentInstance.showGif = showGif;
    dialogRef.componentInstance.isHtml = isHtml;
    dialogRef.componentInstance.primaryButtonText = primaryButtonText;
    dialogRef.componentInstance.secondaryButtonText = secondaryButtonText;
    dialogRef.componentInstance.isTranslateString = isTranslateString;
    dialogRef.componentInstance.manualUrl = manualUrl;
    return dialogRef.afterClosed();
  }

  public companyFieldDialog(
    fieldName: string,
    fieldValue: string,
    isPhone?: boolean,
    oldValue?: string,
    selectedCountry?: string,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<CompanyFieldDialogComponent>;
    dialogRef = this.dialog.open(CompanyFieldDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.fieldName = fieldName;
    dialogRef.componentInstance.fieldValue = fieldValue;
    dialogRef.componentInstance.oldValue = oldValue;
    dialogRef.componentInstance.isPhone = isPhone;
    dialogRef.componentInstance.selectedCountry = selectedCountry;
    return dialogRef.afterClosed();
  }

  public companyAddressFieldDialogComponent(
    streetValue: string,
    houseNumberValue: number,
    houseNumberExtensionValue?: string,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<CompanyAddressFieldDialogComponent>;
    dialogRef = this.dialog.open(CompanyAddressFieldDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.streetValue = streetValue;
    dialogRef.componentInstance.houseNumberValue = houseNumberValue;
    dialogRef.componentInstance.houseNumberExtensionValue = houseNumberExtensionValue;
    return dialogRef.afterClosed();
  }

  public medicationDialog(dialogData): Observable<boolean> {
    const dialogRef = this.dialog.open(MedicationDialogComponent, {
      data: dialogData,
      autoFocus: false,
      maxWidth: '100vw',
      width: '100%',
      maxHeight: '90vh',
      height: 'fit-content',
    });
    return dialogRef.afterClosed();
  }

  public coccidiostatsDialog(dialogData) {
    const dialogRef = this.dialog.open(CoccidiostatsDialogComponent, {
      data: dialogData,
      autoFocus: false,
      maxWidth: '100vw',
      width: '100%',
      maxHeight: '90vh',
      height: 'fit-content',
    });
    return dialogRef.afterClosed();
  }

  public vaccinationsDialog(dialogData) {
    const dialogRef = this.dialog.open(VaccinationsDialogComponent, {
      data: dialogData,
      autoFocus: false,
      maxWidth: '100vw',
      width: '100%',
      maxHeight: '90vh',
      height: 'fit-content',
    });
    return dialogRef.afterClosed();
  }

  public salmonellaDialog(dialogData) {
    const dialogRef = this.dialog.open(SalmonellaDialogComponent, {
      data: dialogData,
      autoFocus: false,
      maxWidth: '100vw',
      width: '100%',
      maxHeight: '90vh',
      height: 'fit-content',
    });
    return dialogRef.afterClosed();
  }

  public stableCardDialog(dialogData) {
    const dialogRef = this.dialog.open(StableTechnicalDialogComponent, {
      data: dialogData,
      autoFocus: false,
      maxWidth: '100vw',
      width: '100%',
      maxHeight: '90vh',
      height: 'fit-content',
    });
    return dialogRef.afterClosed();
  }

  public lossesDialog(dialogData) {
    const dialogRef = this.dialog.open(StableCardDialogComponent, {
      data: dialogData,
      autoFocus: false,
      maxWidth: '100vw',
      width: '100%',
      maxHeight: '90vh',
      height: 'fit-content',
    });
    return dialogRef.afterClosed();
  }

  public templateDialog(template: TemplateRef<any>, config?: MatDialogConfig) {
    return this.dialog.open(template, {
      autoFocus: false,
      maxWidth: '100vw',
      width: '100%',
      maxHeight: '90vh',
      height: 'fit-content',
      ...config,
    });
  }

  public notificationsReminderDialog() {
    const dialogRef = this.dialog.open(NotificationsReminderModalComponent, {
      autoFocus: false,
      maxWidth: '300px',
      width: '100%',
      maxHeight: '300px',
      height: 'fit-content',
    });
    return dialogRef.afterClosed();
  }

  public lossesEditAnimalsAmountDialog(): Observable<boolean> {
    let dialogRef: MatDialogRef<LossesEditAnimalDialogComponent>;
    dialogRef = this.dialog.open(LossesEditAnimalDialogComponent);
    return dialogRef.afterClosed();
  }
}
