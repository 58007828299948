/* eslint-disable prettier/prettier */
import { Injectable, OnDestroy } from '@angular/core';
import { MyTaskService } from '../my-task.service';
import { MyPlukonService } from '../my-plukon.service';
import { VKIService } from '../flock/vki.service';
import * as _ from 'lodash';
import { CodeService } from '../code.service';
import { FlockBasicService } from '../flock/flock-basic/flock-basic.service';
import { FlockOriginService } from '../flock/flock-origin/flock-origin.service';
import { MortalityService } from '../flock/mortality/mortality.service';
import { CoccidiostatsService } from '../flock/coccidiostats/coccidiostats.service';
import { MedicationService } from '../flock/medication/medication.service';
import { VaccinationService } from '../flock/vaccination/vaccination.service';
import { SalmonellaService } from '../flock/salmonella/salmonella.service';
import { VKIRequestService } from '../flock/vki-request.service';
import { AuthGuardService } from '../auth-guard.service';
import { SocialAuthService } from '../social-auth.service';
import { CompanyService } from '../admin/company.service';
import { BehaviorSubject, EMPTY, Subscription, combineLatest, concat, forkJoin, from, interval, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { catchError, concatMap, delay, distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take, tap, toArray } from 'rxjs/operators';
import { getSyncService } from 'src/lib/sync/sync.service';
import { OfflineRequestsService } from './offline-request.service';
import { NetworkService } from '../network/network.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getMyFlockService } from 'src/lib/my-flock';
import { CustomSnackbarComponent } from '../../components/custom-snackbar/custom-snackbar.component';
import { environment } from 'src/environments/environment';
import { getCachedResponsesService } from 'src/lib/responses/cached-responses.service';

const MS_IN_ONE_SECOND = 1000;
const MS_IN_ONE_HOUR = 60 * 60 * MS_IN_ONE_SECOND; // 1 hour
const MS_RESPONSE_VALID = 24 * MS_IN_ONE_HOUR; // 24 hours

@Injectable({
  providedIn: 'root',
})
export class PrecacheService implements OnDestroy {
  private precacheSubscription: Subscription;
  public precaching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private offlineRequestsService: OfflineRequestsService,
    private networkService: NetworkService,
    private myTaskService: MyTaskService,
    private myPlukonService: MyPlukonService,
    private vkiService: VKIService,
    private codeService: CodeService,
    private flockBasicService: FlockBasicService,
    private flockOriginService: FlockOriginService,
    private mortalityService: MortalityService,
    private coccidiostatsService: CoccidiostatsService,
    private medicationService: MedicationService,
    private vaccinationService: VaccinationService,
    private salmonellaService: SalmonellaService,
    private vkiRequestService: VKIRequestService,
    private authGuardService: AuthGuardService,
    private socialService: SocialAuthService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar,
  ) {
  }


  private initialized = false;

  public initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    if (!environment.enableOffline) {
      return;
    }

    let errorWhilePrecaching = false;

    const silentErrorHandler = catchError(() => {
      // console.log('::: Silented error while precaching', { error });
      // do not throw error, but set flag
      errorWhilePrecaching = true;
      return of(null)
    });

    const triggerPrecaching$ = combineLatest([
      this.authService.currentUser$,
      this.networkService.getIsOfflineMode$(),
      this.offlineRequestsService.syncing$,
    ]).pipe(
      map(([user, isOfflineMode, isSyncing]) => {
        const isPrecacheAllowed = user && !isOfflineMode && !isSyncing;
        return isPrecacheAllowed;
      }),
      distinctUntilChanged(),
      switchMap((isPrecacheAllowed) => {
        if (isPrecacheAllowed) {
          return concat(of(null), interval(MS_IN_ONE_HOUR)).pipe(
            // delay to let OfflineRequestsService start and finish syncing if needed
            delay(5 * MS_IN_ONE_SECOND)
          );
        } else {
          return EMPTY;
        }
      }),
      switchMap(() => from(getSyncService().getPrecacheState(this.authService.currentUser?.uid)).pipe(
        // tap((result) => console.log('::: getSyncService().getPrecacheState', result)),
        switchMap((precacheState) => {
          if (!precacheState) {
            return from([{ precacheNeeded: true, msSinceLastPrecache: Number.POSITIVE_INFINITY }]);
          }
          const currentTime = Date.now();
          const lastPrecacheTimestamp = precacheState.timestamp;
          const msSinceLastPrecache = currentTime - lastPrecacheTimestamp;
          if (precacheState.success && msSinceLastPrecache > MS_IN_ONE_HOUR) {
            return from([{ precacheNeeded: true, msSinceLastPrecache }]);
          } else if (!precacheState.success) {
            return from([{ precacheNeeded: true, msSinceLastPrecache }]);
          }
          // console.log('::: precached state is not old enough');
          return from([{ precacheNeeded: false, msSinceLastPrecache }]);
        })
      )),
      switchMap((precacheConfig) => {
        if (precacheConfig.precacheNeeded) {
          return of(precacheConfig);
        } else {
          return EMPTY;
        }
      }),
    );

    let getNonFlockBasedDataComplete = false;
    let getCompanyBasedDataComplete = false;
    let getFlockBasedDataComplete = false;

    const initialPrecaching$ = triggerPrecaching$.pipe(
      tap(() => {
        // This variables are needed because final subscription uses combineLatest.
        // combineLatest emits new value only when all observables have emitted new value.
        // So if precaching will be retriggered, we want that every observable emits new value.
        getNonFlockBasedDataComplete = false;
        getCompanyBasedDataComplete = false;
        getFlockBasedDataComplete = false;
        // console.log('::: trigger precaching')
      })
    )

    const getProfile = initialPrecaching$
      .pipe(
        // tap(() => console.log('::: getMyProfile()')),
        switchMap(
          (precacheConfig) => this.socialService.getMyProfile().pipe(
            map((result) => ({
              isPoultryRole: result['response']['userRoleList'] && result['response']['userRoleList'].length > 0 && (result['response']['userRoleList'].filter((data) => data.roleObj.name === 'Poultry Owner').length > 0 ||
                result['response']['userRoleList'].filter((data) => data.roleObj.name === 'Poultry Employee').length > 0),
              adminFlag: result['response'].admin,
              msSinceLastPrecache: precacheConfig.msSinceLastPrecache,
            }))
          )
        ),
        // tap((result) => console.log('::: profile result', result)),
      );

    const allowedProfileForCaching = getProfile.pipe(
      filter(({ adminFlag, isPoultryRole }) => {
        if (adminFlag || !isPoultryRole) {
          console.log('::: Precaching is disabled for admin users');
          return false;
        }
        return true;
      }),
      tap(() => {
        getSyncService().getPrecacheConfig(this.authService.currentUser?.uid).then((precacheConfig) => {
          if (precacheConfig?.showNotification) {
            this.showPrecacheInProgress();
          }
        });
        this.precaching$.next(true);
      })
    );

    const mainEnvironment = allowedProfileForCaching.pipe(
      // tap(() => console.log('::: getMainEnvironment')),
      switchMap(({ adminFlag, msSinceLastPrecache }) => this.authGuardService.mainEnvironment.pipe(
        filter((profile) => !!profile?.environment),
        map((profile) => {
          const mainEnvironment = profile.environment;
          const isAdminMyTask =
            adminFlag &&
            profile.role &&
            (profile.role === 'Superadmin' ||
              profile.role === 'Admin' ||
              profile.role === 'Planning Employee' ||
              profile.role === 'Sales Representative');
          return { mainEnvironment, isAdminMyTask, msSinceLastPrecache }
        }),
        take(1),
        // tap((result) => console.log('::: mainEnvironment', result))
      )),
      // use shareReplay so that multiple subscribers do not trigger multiple requests
      shareReplay(1),
    );

    const getCompanyList = mainEnvironment.pipe(
      // tap(() => console.log('::: getCompanyList')),
      switchMap(({ mainEnvironment, isAdminMyTask, msSinceLastPrecache }) =>
        this.companyService.getUserPortalCompanyList(mainEnvironment)
          .pipe(
            map(companyListResult => {
              return {
                mainEnvironment,
                isAdminMyTask,
                msSinceLastPrecache,
                companyList: companyListResult['list'].map(data => ({
                  plukonId: data.plukonId ? data.plukonId : '',
                  name: data.name,
                  companyId: data.companyId,
                  country: data.country,
                  isGlobal: data.fromGlobalEnv,
                  companyType: data.companyTypeObj ? data.companyTypeObj.name : null,
                  companyEnvironment: data.environmentObj ? data.environmentObj.shortName.toLowerCase() : null,
                }))
              }
            })
          )
      ),
      // tap((result) => console.log('::: getCompanyList result', result)),
      // use shareReplay so that multiple subscribers do not trigger multiple requests
      shareReplay(1),
    );

    const getNonFlockBasedData = mainEnvironment.pipe(
      // tap(() => console.log('::: getNonFlockBasedData')),
      concatMap(({ isAdminMyTask, msSinceLastPrecache }) => {
        const nonFlockBasedRequests = msSinceLastPrecache > MS_RESPONSE_VALID ? [
          this.codeService.getBlockingDateCode(),
          this.codeService.getFeedTypeCodeList(),
          this.codeService.getFeedClinicalCodeList(),
          this.codeService.getIllnessCodeList(),
          this.codeService.getUnitCodeList(),
          this.codeService.getVaccinationIllnessCodeList(),
          this.codeService.getMethodCodeList(),
          this.codeService.getSubTypeList(),
          this.codeService.getWheatUse(),
          this.codeService.getReportTypeCode(),
          this.codeService.getWaterUsage(),
          this.codeService.getLitterType(),
          this.codeService.getStableLogTimer(),
          this.codeService.getOtherDiseasesBE(),
          this.codeService.getApprovingCode(),
          this.codeService.getCountryCode(),
          this.codeService.getLastDeliveryCode(),
          this.codeService.getDeliveryTypeCode(),
          this.vkiService.getWeightGainBaseCode(),
          this.flockBasicService.getFeedingLocation(),
          this.flockOriginService.getBreedList(),
          this.flockOriginService.getBreedingCompanyList(),
          this.salmonellaService.getResultList(),
          this.salmonellaService.getLaboratoryList(),
        ] : [];
        if (!isAdminMyTask) {
          nonFlockBasedRequests.push(this.myTaskService.getUserTask());
        }
        // make requests one by one
        return from(nonFlockBasedRequests).pipe(
          concatMap(request => request.pipe(silentErrorHandler)),
          toArray() // Collect all responses into an array
        )
      }),
      tap(() => {
        getNonFlockBasedDataComplete = true;
        // console.log('::: getNonFlockBasedData complete');
      }),
    );

    const getCompanyBasedData = getCompanyList.pipe(
      // tap(() => console.log('::: getCompanyBasedData(all companies)')),
      concatMap(({ companyList, mainEnvironment, isAdminMyTask, msSinceLastPrecache }) => {
        if (msSinceLastPrecache > MS_RESPONSE_VALID) {
          const countryList = [];
          return from(companyList).pipe(
            mergeMap(({ plukonId, companyId, country }) => {
              const companyBasedRequests = [];
              const isGetVKIConfig = !countryList.includes(country);
              if (isAdminMyTask) {
                companyBasedRequests.push(this.myTaskService.getCompanyTask(companyId));
              }

              companyBasedRequests.push(this.myPlukonService.getConceptLayout(mainEnvironment, companyId));
              companyBasedRequests.push(this.flockBasicService.getHatcheryList(plukonId));
              companyBasedRequests.push(this.flockBasicService.getFeedSupplier(plukonId));
              companyBasedRequests.push(this.flockBasicService.getVetenarianList(plukonId));

              if (isGetVKIConfig) {
                countryList.push(country);
                companyBasedRequests.push(this.vkiService.getVKIConfig(mainEnvironment, country))
                companyBasedRequests.push(
                  this.vkiRequestService.getSlaughterLocationList()
                    .pipe(
                      mergeMap((slaughterLocationListResult) => {
                        const slaughterLocationRequests = [];
                        if (slaughterLocationListResult && slaughterLocationListResult['list']) {
                          const slaughterCountryList = [];
                          for (let j = 0; j < slaughterLocationListResult['list'].length; j++) {
                            const { slaughterId, countryCode } = slaughterLocationListResult['list'][j];
                            if (!slaughterCountryList.includes(countryCode)) {
                              slaughterCountryList.push(countryCode);
                              slaughterLocationRequests.push(
                                this.vkiService.getVKIConfig(mainEnvironment, country, countryCode)
                              )
                            }
                            slaughterLocationRequests.push(
                              this.vkiRequestService.getSlaughterLocationInEnvironment(mainEnvironment, slaughterId)
                            )
                          }
                        }
                        return forkJoin(slaughterLocationRequests).pipe(
                          // tap(() => console.log('::: slaughterLocation request complete, delay 1 sec')),
                          silentErrorHandler);
                      }, 1)
                    )
                )
              }

              return forkJoin(companyBasedRequests.map((request) => request.pipe(silentErrorHandler))).pipe(
                // tap(() => console.log(`::: getCompanyBasedData for ${companyId} - ${plukonId} - completed\n\n`)),
                delay(1000), // DELAY BEFORE NEXT COMPANY BASED DATA REQUESTS
              );
            }, 1),
            toArray(),
          )
        }
        return EMPTY;
      }),
      tap(() => {
        getCompanyBasedDataComplete = true;
        // console.log('::: getCompanyBasedData(all companies) - complete');
      }),
    )

    const getFlockBasedData = getCompanyList.pipe(
      // tap(() => console.log('::: getFlockBasedData (all companies)')),
      concatMap(({ companyList, mainEnvironment }) => {
        return from(companyList).pipe(
          concatMap(
            // sequential requests: getStableList
            ({ companyId }) => this.vkiService.getStableList(companyId).pipe(
              map((result) => (result?.['list'] || []).map(value => ({
                plukonId: value.plukonId,
                name: value.name,
              }))),
              // tap((stables) => console.log(`::: getFlockBasedData:getStableList for ${companyId}`, stables)),
              // returns
              concatMap((stableList) => {
                // sequential requests: getFlockBoxInfo
                return from(stableList).pipe(
                  concatMap(({ plukonId }) => {
                    const uid = this.authService.currentUser?.uid;
                    const outdatedPromise = getMyFlockService().isFlockBasicsOutdated({ uid, flockBasicsGetUrl: this.vkiService.getFlockBoxInfoUrl(plukonId), throwNotFound: false });
                    return from(outdatedPromise).pipe(
                      switchMap((isOutdated) => {
                        // console.log(plukonId + isOutdated);
                        if (isOutdated) {
                          if (this.networkService.isOfflineMode) {
                            // This can happen if offline mode is enabled while precaching is in progress
                            // But getFlockBoxInfo get response from cache, so this prevents further requests and possible showDataOutdatedNotification
                            throw Error('Stop precaching in offline mode');
                          }
                          return this.vkiService.getFlockBoxInfo(plukonId).pipe(
                            // parallel requests: flock based data
                            switchMap((flockBoxInfoResult) => {
                              const flockNumber = flockBoxInfoResult?.['response']?.flockNumber;
                              const flockDataRequests = [];
                              if (flockNumber) {
                                flockDataRequests.push(this.mortalityService.getMortality(flockNumber));
                                flockDataRequests.push(this.vkiService.getCopyStableList(flockNumber));
                                flockDataRequests.push(this.vkiService.getFlockTechnical(flockNumber));
                                flockDataRequests.push(this.flockOriginService.getFlockOrigin(flockNumber));
                                flockDataRequests.push(this.salmonellaService.getSalmonella(flockNumber));
                                flockDataRequests.push(this.vaccinationService.getVaccination(flockNumber));
                                flockDataRequests.push(this.medicationService.getMedication(flockNumber));
                                flockDataRequests.push(this.coccidiostatsService.getCoccidiostats(flockNumber));
                                flockDataRequests.push(this.vkiService.getLastUpload(plukonId, flockNumber));
                                flockDataRequests.push(this.vkiService.getStableLog(flockNumber));
                                flockDataRequests.push(this.vkiService.getStableLogTechnical(flockNumber));
                                flockDataRequests.push(this.vkiService.getTechnicalLastUpload(plukonId, flockNumber));
                                flockDataRequests.push(this.vkiRequestService.getVKIRequestData(mainEnvironment, flockNumber));

                                // add here flock based data requests
                                flockDataRequests.push(this.coccidiostatsService.getCoccidiostatsList(plukonId));
                                flockDataRequests.push(this.medicationService.getAntiBioticList(plukonId));
                                flockDataRequests.push(this.vaccinationService.getAntiBioticList(plukonId));
                              }
                              return forkJoin(flockDataRequests.map(request => request.pipe(silentErrorHandler)))
                            }),
                            // tap(() => console.log(`::: getFlockBasedData for ${companyId} - ${plukonId} - completed`)),
                            delay(500), // delay before flock based data requests for next stable
                            silentErrorHandler,
                          )
                        } else {
                          return EMPTY;
                        }
                      })
                    )
                  }),
                  toArray(),
                )
              }),
              silentErrorHandler
            )
          ),
          toArray()
        )
      }),
      tap(() => {
        getFlockBasedDataComplete = true;
        // console.log('::: getFlockBasedData (all companies) - complete');
      }),
    )

    this.precacheSubscription =
      combineLatest([getNonFlockBasedData, getCompanyBasedData, getFlockBasedData])
        .pipe(silentErrorHandler)
        .pipe(
          filter(() => getNonFlockBasedDataComplete && getCompanyBasedDataComplete && getFlockBasedDataComplete),
          switchMap(() => {
            return from(getCachedResponsesService().cleanupOldCachedResponses()).pipe(silentErrorHandler);
          }),
          tap(() => {
            const uid = this.authService.currentUser?.uid;
            this.precaching$.next(false);
            if (!uid) {
              console.warn('::: Precaching state cannot be saved as user is not logged in');
              return;
            }
            if (this.networkService.isOfflineMode) {
              console.warn('::: Precaching state cannot be saved as network is offline');
              return;
            }
            console.log('::: Precaching complete', { errorWhilePrecaching, uid });
            if (errorWhilePrecaching) {
              getSyncService().storePrecacheFailure(uid);
            } else {
              getSyncService().storePrecacheSuccess(uid);
              getSyncService().getPrecacheConfig(uid).then((precacheConfig) => {
                if (precacheConfig?.showNotification) {
                  getSyncService().storePrecacheConfig(uid, false);
                  this.showPrecacheComplete();
                }
              });
            }
          })
        )
        .subscribe();
  }

  private showPrecacheInProgress() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: 'Precache.CacheInProgress',
      verticalPosition: 'top',
      panelClass: ['orange'],
    });
  }

  private showPrecacheComplete() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: 'Precache.CacheCompleted',
      verticalPosition: 'top',
      panelClass: ['green'],
    });
  }


  ngOnDestroy(): void {
    if (this.precacheSubscription) {
      this.precacheSubscription.unsubscribe();
    }
  }
}
